.login-error {
  border: 2px solid #f00e0e;
  margin-top: 4rem;
  width: max-content;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff7f7;
  text-align: center;
}