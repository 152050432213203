.form {
    min-height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
    &__wrapper {
        padding: 40px;
        display: grid;
        grid-gap: 40px;
        margin: 0 auto;
        width: 100%;
        grid-template-columns: minmax(0, 1fr);
        max-width: 680px;
        @media (min-width: 1020px) {
            max-width: 1020px;
            grid-template-columns: 300px minmax(0, 1fr);
        }
    }
    &__details {
    }
    &__signature {
    }
}
