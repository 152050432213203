.login-wrapper {
    min-width: 100vw;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
}
.login {
    max-width: 400px;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    padding: 40px;
    button {
        height: 60px;
        width: 190px;
    }
    &__header {
        padding-bottom: 30px;
    }
    &__children {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}
h1 {
    span {
        color: #00c1d5;
    }
}
