html,
body,
#root,
.App {
    height: 100%;
}
body {
    color: #1b1b1b;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
* {
    box-sizing: border-box;
}
::-moz-selection {
    /* Code for Firefox */
    background: rgba(0, 193, 213, 0.5);
}
::selection {
    background: rgba(0, 193, 213, 0.5);
}
h1,
h2,
h3 {
    font-family: 'Crimson Text', Arial, Helvetica, sans-serif;
}
h1 {
    margin-top: 0;
}
.content {
    max-width: 600px;
    margin: 0 auto;
    padding: 0 30px;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.wrapper {
    padding: 40px 0;
    text-align: center;
}
.signature {
    padding: 20px;
    background-color: white;
    display: block;
    border: 1px solid #1b1b1b;
    max-width: 100%;
    text-align: left;
    overflow-x: auto;
}
form {
    margin-bottom: 20px;
}
.row {
    display: flex;
    max-width: 300px;
    width: 100%;
    align-items: center;
    padding: 5px 0;
    label {
        text-align: left;
        margin-right: 15px;
        width: 60px;
        font-size: 15px;
    }
}
input {
    border: 1px solid #1b1b1b;
    line-height: 35px;
    height: 37px;
    padding: 0 15px;
    display: block;
    width: 100%;
}
button {
    border: 1px solid #1b1b1b;
    border-radius: 4px;
    height: 35px;
    padding: 0 15px;
    cursor: pointer;
    color: #1b1b1b;
    background-color: white;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    outline: none;
}
button.copied {
    background-color: #1b1b1b;
    color: white;
}
#generated-signature {
    overflow: hidden;
}
