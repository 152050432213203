.header {
    border-bottom: 1px solid #efefef;
    &__container {
        padding: 20px 30px;
        @media (min-width: 500px) {
            display: flex;
            justify-content: space-between;
        }
        @media (min-width: 800px) {
            max-width: 1020px;
            margin: 0 auto;
        }
    }
    &__logo {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        @media (min-width: 500px) {
            margin-bottom: 0;
        }
        h1 {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            font-size: 20px;
            width: 100%;
            @media (min-width: 500px) {
                width: auto;
                justify-content: flex-start;
            }
            @media (min-width: 800px) {
                font-size: 32px;
            }
        }
        .logo {
            margin-right: 20px;
            width: 50px;
            @media (min-width: 800px) {
                width: 85px;
            }
        }
    }
    &__sign-out {
        display: flex;
        align-items: center;
        justify-content: center;
        @media (min-width: 500px) {
            justify-content: flex-start;
        }
    }
    .user {
        margin-right: 20px;
        &__details {
            display: none;
            @media (min-width: 800px) {
                display: flex;
            }
        }
    }
}
