.user {
    @media (min-width: 500px) {
        display: grid;
        grid-template-areas: 'details image';
        grid-gap: 0 20px;
    }
    &__image {
        width: 50px;
        height: 50px;
        overflow: hidden;
        border-radius: 50%;
        border: 1px solid #efefef;
        grid-area: image;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            display: block;
        }
    }
    &__details {
        grid-area: details;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__name {
        font-size: 14px;
        font-weight: bold;
    }
    &__email {
        font-size: 14px;
    }
}
